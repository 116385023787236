<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 18 18.196"
    >
      <path
        id="Path_11525"
        data-name="Path 11525"
        d="M1236.378,628.33a4.451,4.451,0,0,1-3.137-7.607,4.5,4.5,0,0,1,2.705-1.294h.078a5.769,5.769,0,0,0,5.1-5.451,4.623,4.623,0,0,1,1.255-2.549,4.45,4.45,0,1,1,6.274,6.313,4.309,4.309,0,0,1-2.275,1.216h0l-.156.039a5.583,5.583,0,0,0-3.725,1.687,5.772,5.772,0,0,0-1.687,3.725v.157a4.44,4.44,0,0,1-4.431,3.765Z"
        transform="translate(-1231.946 -610.134)"
        fill="#f6c"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfArtFilterIcon",
  props: {
    title: {
      type: String,
      default: "Art Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>