<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-conversation-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      :fill="props.fillColor"
      class="material-design-icon__svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 25 25"
    >
      <g id="Group_6967" transform="translate(-20.252 -1721.25)" class="st0">
        <path id="Path_1232" class="st1" d="M40,1724.1v-2.1H21v14.9h1.5" />
        <path id="Path_1233" class="st1" d="M31.2,1745l4-4H44v-15.2H24v15.2h3.5L31.2,1745z" />
        <circle id="Ellipse_490" cx="29.5" cy="1733.4" r="1.5" />
        <circle id="Ellipse_491" cx="34.5" cy="1733.4" r="1.5" />
        <circle id="Ellipse_492" cx="39.5" cy="1733.4" r="1.5" />
      </g>
      <title>{{ props.title }}</title>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfConversationIcon",
  props: {
    title: {
      type: String,
      default: "Conversation icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 30
    }
  }
};
</script>

<style scoped>
.st0 {
  opacity: 0.3;
}
.st1 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
}
</style>