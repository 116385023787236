<template>
  <div class="main">
    <MapHeader v-if="panelsShown"></MapHeader>
    <RouteMapSource></RouteMapSource>
    <SidePanel></SidePanel>
    <FilterPanel></FilterPanel>
    <RouteMap v-if="panelsShown" id="routeMap" :zoom="zoom" :interactive="true" :center="center" :showActions="true"></RouteMap>
  </div>
</template>

<script>
import RouteMap from "./RouteMap.vue";
import RouteMapSource from "./RouteMapSource.js";
import SidePanel from "../layout/side-panel/SidePanel.vue";
import FilterPanel from "../layout/filter-panel/FilterPanel.vue";
import MapHeader from "./MapHeader.vue";
import { store } from "@/store.js";

export default {
  name: "MapHolder",
  components: {
    RouteMap,
    MapHeader,
    SidePanel,
    FilterPanel,
    RouteMapSource,
  },
  computed: {
     panelsShown() {
       return store.panelsShown;
     }
  },
  data() {
    return {
      zoom: 10.75,
      center: [4.806489944458008, 52.33461868286133]
    };
  },
};
</script>

<style>
.main {
  flex: 1;
}
</style>