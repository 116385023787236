<template>
  <router-link :to="routerLink" class="router-link">
    <plus-box-outline-icon
      :size="24"
      class="toggle-button"
      id="side-panel-toggle"
      :class="{ active: isSidePanelToggleActive }"
      @click="toggle"
    ></plus-box-outline-icon>
  </router-link>
</template>

<script>
import { store, mutations } from "@/store.js";

export default {
  computed: {
    isSidePanelToggleActive() {
      return store.isSidePanelOpen;
    },
    routerLink() {
      return store.isSidePanelOpen ? "/context/feature" : "/context";
    },
  },
  methods: {
    toggle() {
      mutations.toggleSidePanel();
    },
  },
};
</script>

<style scoped>
</style>
