<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 24.086 17.371"
    >
      <g id="Gfx" transform="translate(0 0)">
        <circle id="Ellipse_924" data-name="Ellipse 924" cx="8.685" cy="8.685" r="8.685" />
        <circle
          id="Ellipse_925"
          data-name="Ellipse 925"
          cx="1.63"
          cy="1.63"
          r="1.63"
          transform="translate(20.827 7.056)"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfAdaptiveSpeedFilterIcon",
  props: {
    title: {
      type: String,
      default: "Adaptive Speed Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "#ff3333",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>