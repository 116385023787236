<script>
import { store, mutations, OBJECT_TYPE } from "@/store.js";
import { Teatree, NodeName, NodeToggle } from "vue-teatree";
import { EventBus } from "@/components/EventBus.js";
import FilterTreeIcon from "./FilterTreeIcon.vue";

export default {
  methods: {
    closeFilterPanel: mutations.toggleFilterPanel,
    handleClick(node) {
      if (!node.disabled) {
        if (node.type == OBJECT_TYPE.EBIKE) {
          store.selectedObject = store.selectedRoute.entities.find(entity => {return entity.bikeId == node.objectId});
        } else if (node.type == OBJECT_TYPE.ASC) {
          store.selectedObject = store.selectedSegment.features[0]; // XXX: always select the V130 feature
        } else {
          store.selectedObject = null;
        }
        EventBus.$emit('selectionChange');
      }
    },
    unselectAll(nodes) {
      var that = this;
      nodes.forEach(function (node) {
        node.selected = false;
        that.unselectAll(node.children);
      });
    },
    onSelectionChange() {
      this.unselectAll(this.data);
      if (store.selectedObject) {
        var selectedFilterEntry = null;
        if (store.selectedObject.type == OBJECT_TYPE.EBIKE) {
          selectedFilterEntry = store.filters.vehicles.eBikes.find(entity => {return entity.bikeDisplayId == store.selectedObject.bikeDisplayId});
        } else if (store.selectedObject.type == OBJECT_TYPE.ASC) {
          selectedFilterEntry = store.filters.ascs.find(entity => {return "ASC." + entity.name == store.selectedObject.id})
        }
        if (selectedFilterEntry) selectedFilterEntry.selected = true;
      }
    }
  },
  created() {
    this.onSelectionChange();
    EventBus.$on('selectionChange', this.onSelectionChange);
  },
  data() {
    return {
      data: [
        {
          name: "Vehicles",
          show: true,
          showChildren: true,
          icon: "pf-vehicles-filter-icon",
          selected: true,
          children: [
            {
              name: "Cars",
              show: true,
              showChildren: false,
              selected: false,
              children: [],
              disabled: true,
            },
            {
              name: "eScooters",
              show: true,
              showChildren: false,
              selected: false,
              children: [],
              disabled: true,
            },
            {
              name: "eBikes",
              show: true,
              showChildren: true,
              selected: true,
              disabled: false,
              children: store.filters.vehicles.eBikes,
            },
          ],
        },
        {
          name: "Intelligent Paths",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-paths-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Networked Hubs",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-hubs-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Connected Travellers",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-traveler-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Adaptive Speed Controls",
          show: true,
          showChildren: true,
          selected: true,
          icon: "pf-adaptivespeed-filter-icon",
          disabled: false,
          children: store.filters.ascs,
        },
        {
          name: "Situational Trafic Controls",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-stc-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Illumination",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-illumination-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Dynamic Signage",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-signage-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Network beacons",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-network-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Greenery",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-green-filter-icon",
          children: [],
          disabled: true,
        },
        {
          name: "Interactive Art",
          show: true,
          showChildren: true,
          selected: false,
          icon: "pf-art-filter-icon",
          children: [],
          disabled: true,
        },
      ],
    };
  },
  computed: {
    isFilterPanelOpen() {
      return store.isFilterPanelOpen;
    },
  },
  components: { Teatree, NodeName, NodeToggle, FilterTreeIcon },
};
</script>

<template>
  <div class="side-panel">
    <transition name="slide">
      <div
        v-show="isFilterPanelOpen"
        class="side-panel-panel side-panel-panel-filter"
        id="main-filter-panel"
      >
        <Teatree :roots="data">
          <template slot="node-toggle" slot-scope="{ node }">
            <FilterTreeIcon :icon="node.icon" />
            <NodeToggle :node="node" />
          </template>
          <template slot="node-name" slot-scope="{ node }">
            <NodeName
              :node="node"
              :handleNodeLeftClick="handleClick"
              :handleNodeRightClick="() => {}"
            />
          </template>
        </Teatree>
      </div>
    </transition>
  </div>
</template>

<style>
.teatree {
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding: 20px;
  font-weight: bold;
  background: #333333;
}

.teatree-node {
  padding-right: 0.25rem;
}

.teatree-node-item {
  display: flex;
  align-items: center;
  height: 2rem;
  background: transparent;
  color: #666666;

  /* hack to make hover the full width of parent */
  padding-left: 100%;
  margin-left: -100%;
  padding-right: 100%;
  margin-right: -100%;
}

.teatree-node-item:hover {
  color: #ffffff;
}

.teatree-node-item-selected,
.teatree-node-item-selected .teatree-node-item-name {
  color: #ffffff;
}

.teatree-node-item-name-padded-leaf {
  padding-left: 1.25rem;
}
.teatree-node-item-icon {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: #666666;
}
.teatree-node-item-name {
  display: inline-block;
  font-size: 0.875rem;
  color: #666666;
  margin-left: 0.5rem;
  user-select: none;

  /* truncate */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teatree-node-item-name-padded {
  padding-left: 1.5rem;
}

.side-panel-panel-filter {
  width: 25%;
  border-top: none;
  border-bottom: none;
  height: calc(100% - 236px);
}
</style>
