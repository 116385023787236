import uuid from "uuid";
import { store, mutations } from "@/store.js";
import WebsocketClient from "@/websocket-client.js";
import { OBJECT_TYPE } from "../../store";
import { EventBus } from "../EventBus";

var selectedRoute = store.selectedRoute; // select snelfietsroute
var feat = store.selectedSegment.features[0]; // select the speed control feature

export default {
    name: "RouteMapSource",
    mixins: [],
    computed: {},
    methods: {
        checkSelectedQueryEntity() {
            if (this.$route.query.eBike) {
              var eBikeQueryId = this.$route.query.eBike.toUpperCase().trim();
              store.selectedObject = store.selectedRoute.entities.find(entity => {return entity.bikeDisplayId == eBikeQueryId});
              EventBus.$emit('selectionChange');
            } else if (this.$route.query.asc) {
                store.selectedObject = store.selectedSegment.features[0];
                EventBus.$emit('selectionChange');
            }
        }      
    },
    watch: {
        $route () {
            this.checkSelectedQueryEntity();
        }
    },
    created() {
        var route = selectedRoute.route, routeHighlight = selectedRoute.routeHighlight, routeHighlightDetail = selectedRoute.routeHighlightDetail;
        var stepSize = selectedRoute.stepSize, featLength = feat.end - feat.start;
        var segmentStart = feat.start - featLength / 4 * 3, segmentEnd = feat.end + featLength / 4 * 3;
        var segmentHighlightStart = feat.start;
        var segmentHighlightEnd = feat.end;

        var hundredKphTailLen = 50 / stepSize; // 50 meter tail for 100 kph
        var turf = this.$turf;
        var routePoints = new Array();

        // route.features[0] = turf.bezierSpline(route.features[0]);
        var lineDistance = turf.lineDistance(route.features[0], {
            units: "meters"
        });
        var steps = lineDistance / stepSize;

        for (var i = 0; i < lineDistance; i += lineDistance / steps) {
            var bit = turf.along(route.features[0], i, { units: "meters" });
            routePoints.push(bit.geometry.coordinates);
        }
        route.features[0].geometry.coordinates = routePoints;
        routeHighlight.features[0].geometry.coordinates = routePoints.slice(
            Math.trunc((segmentStart) / stepSize),
            Math.trunc((segmentEnd) / stepSize)
        );
        routeHighlightDetail.features[0].geometry.coordinates = routePoints.slice(
            Math.trunc(segmentHighlightStart / stepSize),
            Math.trunc(segmentHighlightEnd / stepSize)
        );
        var outStartIdx = Math.trunc(feat.start / stepSize), inStartIdx = Math.trunc(feat.end / stepSize);
        // green ends are 15% of highlight, blend to red (through orange are 15%) and limited red is 40% of highlight -> ends stretch out 37.5% of limited area further, pull back a little to visually align with blend to 35%
        var outEndIdx = inStartIdx + (inStartIdx - outStartIdx) * .35 | 0, inEndIdx = outStartIdx - (inStartIdx - outStartIdx) * .35 | 0;
        var outStartAngleRad = (360 - turf.bearing(routePoints[outStartIdx], routePoints[outStartIdx+1])) * Math.PI / 180;
        var outEndAngleRad = (360 - turf.bearing(routePoints[outEndIdx-1], routePoints[outEndIdx])) * Math.PI / 180;
        var inStartAngleRad = (360 - turf.bearing(routePoints[inStartIdx], routePoints[inStartIdx+1])) * Math.PI / 180;
        var inEndAngleRad = (360 - turf.bearing(routePoints[inEndIdx-1], routePoints[inEndIdx])) * Math.PI / 180;
        var inboundStartOffset = selectedRoute.routeHighlightMarkersInboundStartOffsetPx, outboundStartOffset = selectedRoute.routeHighlightMarkersOutboundStartOffsetPx, endOffset = selectedRoute.routeHighlightMarkersEndOffsetPx;
        routeHighlightDetail.features[1].geometry.coordinates = routePoints[outStartIdx]; // outbound start
        routeHighlightDetail.features[1].translate = [outboundStartOffset * Math.cos(outStartAngleRad), -outboundStartOffset * Math.sin(outStartAngleRad)];
        routeHighlightDetail.features[2].geometry.coordinates = routePoints[outEndIdx]; // outbound end
        routeHighlightDetail.features[2].translate = [endOffset * Math.cos(outEndAngleRad), -endOffset * Math.sin(outEndAngleRad)];
        routeHighlightDetail.features[3].geometry.coordinates = routePoints[inStartIdx]; // inbound start
        routeHighlightDetail.features[3].translate = [-inboundStartOffset * Math.cos(inStartAngleRad), inboundStartOffset * Math.sin(inStartAngleRad)];
        routeHighlightDetail.features[4].geometry.coordinates = routePoints[inEndIdx]; // inbound end
        routeHighlightDetail.features[4].translate = [-endOffset * Math.cos(inEndAngleRad), endOffset * Math.sin(inEndAngleRad)];

        var featureCenter = turf.center(routeHighlightDetail);
        selectedRoute.actionMarkers.feature.coordinates = featureCenter.geometry.coordinates;
        var rhdBbox = turf.bbox(routeHighlightDetail.features[0]), rhdBboxH = turf.distance([rhdBbox[0], rhdBbox[1]], [rhdBbox[2], rhdBbox[3]], { units: "meters" }), rhdBCircle = turf.circle(featureCenter, rhdBboxH/2, { units: "meters" });
        selectedRoute.actionMarkers.feature.container = turf.transformScale(turf.bboxPolygon(turf.bbox(rhdBCircle)), 1.6);
        var cntc = selectedRoute.actionMarkers.feature.container.geometry.coordinates;
        selectedRoute.actionMarkers.feature.size = turf.distance(cntc[0][0], cntc[0][3], { units: "meters" }) * .8;

        function spawnEntity(start, uid) {
            // var minSpeed = 35,
            //     maxSpeed = 60;
            return {
                bikeId: uid ? uid : uuid.v4(),
                pos: start ? 0 : lineDistance / 4 + Math.random() * lineDistance / 2,
                // speed: Math.trunc(Math.random() * (maxSpeed - minSpeed)) + minSpeed,
                timestamp: new Date(),
                dir: Math.random() >= 0.5 ? "outbound" :  "inbound",
                events: [],
                type: OBJECT_TYPE.EBIKE
            };
        }

        var outbound = route.features[0].geometry.coordinates; //, inbound = outbound.slice(0).reverse();

        function isRestricted(e) {
            return process.env.VUE_APP_BIKE_EVENTS_MODE === "generated" && feat.speedLimitEnabled && feat.start < e.pos && e.pos < feat.end
                || process.env.VUE_APP_BIKE_EVENTS_MODE === "streamed" && e.geoFenced;
        }

        function pushEntity(entity) {
            var bikeDisplayId = entity.bikeId.substr(entity.bikeId.length-6).trim().toUpperCase();
            var bikeDisplayName = "eBike ID " + bikeDisplayId;
            selectedRoute.entities.push(entity);
            entity.displayName = bikeDisplayName;
            entity.bikeDisplayId = bikeDisplayId;
            store.filters.vehicles.eBikes.push({
                name: bikeDisplayName,
                bikeDisplayId: bikeDisplayId,
                show: true,
                showChildren: false,
                selected: false,
                children: [],
                type: OBJECT_TYPE.EBIKE,
                objectId: entity.bikeId
            });
            EventBus.$emit('entityPushed');
        }

        function dropEntity(idx) {
            var entityToDrop = selectedRoute.entities[idx];
            var idToDrop = entityToDrop.bikeId;

            // remove from map
            selectedRoute.entities.splice(idx, 1);
            if (entityToDrop == store.selectedObject) {
                store.selectedObject = null;
                EventBus.$emit('selectionChange');
            }

            // remove from filter
            var i = store.filters.vehicles.eBikes.length;
            while (i--) {
                var eBike = store.filters.vehicles.eBikes[i];
                if (eBike.objectId == idToDrop) {
                    store.filters.vehicles.eBikes.splice(i,1);
                    return;
                }
            }
        }

        function drawEntities() {
            selectedRoute.outboundFeatures = [];
            selectedRoute.inboundFeatures = [];
            selectedRoute.selectedObjectFeatures = [];

            var i = selectedRoute.entities.length;
            while (i--) {
                var e = selectedRoute.entities[i];
                // if (!e.speed) continue;
                if (process.env.VUE_APP_BIKE_EVENTS_MODE === "generated" && (e.pos > lineDistance - 5 || e.pos < 5)) {
                    // out of bounds, can remove it
                    dropEntity(i);
                    // replace with newly generated entity if required
                    if (process.env.VUE_APP_BIKE_EVENTS_MODE === "generated") {
                        pushEntity(spawnEntity(false, e.bikeId));
                    }
                    continue;
                }

                var speed = e.speed;
                var cHeadIdx = e.headIdx,
                    cTailLen = e.tailLen;
                
                e.headIdx = (e.pos / lineDistance) * outbound.length | 0;
                e.coordinates = outbound[e.headIdx];
                e.tailLen = Math.max(Math.trunc((speed / 100) * hundredKphTailLen), 4);  // min 4 points for tail so it doesn't dissapear when stopped    

                if (cTailLen) {
                    var headAdvance = Math.abs(e.headIdx - cHeadIdx),
                    accelerating = e.speed > e.speedFrom,
                    maxTailChange = accelerating ? headAdvance : -headAdvance * e.speedFrom / e.speed | 0;
                    e.tailLen = accelerating ? Math.min(cTailLen + maxTailChange, e.tailLen) : Math.max(cTailLen + maxTailChange, e.tailLen);
                }
                if (!e.speedHistory) {
                    e.speedHistory = [];
                    e.speedHistory.push(e.speed);
                }
                // e.displaySpeed = e.tailLen * 100 / hundredKphTailLen;

                var bIdx1 = e.dir == "outbound" ? e.headIdx-1 : e.headIdx;
                var bIdx2 = e.dir == "outbound" ? e.headIdx : e.headIdx-1;
                e.bearing = turf.bearing(routePoints[bIdx1], routePoints[bIdx2]);

                if (e == store.selectedObject) {
                    EventBus.$emit('bikeMoved');
                }
                if (e.dir === "outbound") {
                    selectedRoute.outboundFeatures.push({
                        type: "Feature",
                        properties: {
                            bikeId: e.bikeId
                        },
                        geometry: {
                            type: "LineString",
                            coordinates: outbound
                                .slice(e.headIdx - e.tailLen, e.headIdx)
                                .reverse()
                        }
                    });
                } else if (e.dir === "inbound") {
                    selectedRoute.inboundFeatures.push({
                        type: "Feature",
                        properties: {
                            bikeId: e.bikeId
                        },
                        geometry: {
                            type: "LineString",
                            coordinates: outbound
                                .slice(e.headIdx, e.headIdx + e.tailLen)
                        }
                    });
                } else {
                    // console.log("unclear which way it goes ", e);
                }
            }

            selectedRoute.outboundTraffic.features = selectedRoute.outboundFeatures;
            selectedRoute.inboundTraffic.features = selectedRoute.inboundFeatures;
            selectedRoute.selectedTrafficObject.features = selectedRoute.selectedObjectFeatures;
        }

        var timeShift = parseInt(process.env.VUE_APP_MAP_RENDER_TIME_SHIFT || 2500); // millis in the past
        var lastUpdate = new Date();

        function updateEntities() {
            var thisUpdate = new Date(), timePassed = thisUpdate - lastUpdate;
            lastUpdate = thisUpdate;
            var i = selectedRoute.entities.length;
            while (i--) {
                var e = selectedRoute.entities[i];
                if (!e.speed) continue;
                e.assistOff = isRestricted(e);
                var speed = e.speed;
                e.pos = e.pos + speed * timePassed / 3600 * (e.dir == "outbound" ? 1 : -1);
                e.timestamp = thisUpdate - timeShift;
            }
        }

        var frameLen = parseInt(process.env.VUE_APP_MAP_RENDER_FRAME_LEN || 40); // 1000/x fps

        function animate() {
            updateEntities();
            drawEntities();
        }

        function processEvents() {
            while (store.eventQueue.length) {
                var event = store.eventQueue.shift();
                var now = Date.now();
                event.timestamp = parseInt(event.timestamp.toString());
                var displayDelay = event.timestamp + timeShift - now;
                console.log("processing event from " + (now - event.timestamp) + " millis ago, will handle in " + displayDelay + " millis.");
                if (process.env.VUE_APP_BIKE_EVENTS_MODE === "streamed" && displayDelay < 0) {
                    continue; // when streaming, discard events earlier than timeShift in the past
                }

                var location = [event.lon, event.lat];
                // var pointOnRoute = turf.nearestPointOnLine(selectedRoute.route.features[0], turf.point(location), {units: 'meters'});
                var idxOfPointOnRoute = outbound.findIndex(function (el) {
                    var longDif = Math.abs((el[0] - location[0]) / Math.cos(location[1])), latDif = Math.abs(el[1] - location[1]);
                    return longDif < 0.000015 && latDif < 0.000018; // first point found with error < 2 meters error at 52 deg lat
                    // return el[0] === location[0] && el[1] === location[1];
                });

                if (idxOfPointOnRoute < 0) {
                    console.warn("miss for " + location);
                    selectedRoute.eventMisses.features.push({
                            type: "Feature",
                            geometry: {
                                coordinates: location,
                                type: "Point"
                            }
                        });
                    continue;
                } else {
                    selectedRoute.eventHits.features.push({
                        type: "Feature",
                        geometry: {
                            coordinates: location,
                            type: "Point"
                        }
                    });
                }

                // update event to the location on route
                Object.assign(event, {
                    origLon: event.lon,
                    origLat: event.lat,
                    lon: location[0],
                    lat: location[1],
                    pos: idxOfPointOnRoute / outbound.length * lineDistance // relative to route's main direction - outbound
                })

                var e = null;
                for (var i = 0; i < selectedRoute.entities.length; i++) {
                    if (event.bikeid == selectedRoute.entities[i].bikeId) {
                        e = selectedRoute.entities[i];
                        break;
                    }
                }
                if (e == null) { // new bike
                    e = {
                        bikeId: event.bikeid,
                        pos: event.pos,
                        timestamp: event.timestamp,
                        dir: null,
                        events: [],
                        type: OBJECT_TYPE.EBIKE
                    };
                    pushEntity(e);
                }

                e.geoFenced = !!event.geoFenced
                e.events.push(event);
                if (e.events.length == 1) {
                    e.pos = event.pos;
                    continue; // this was the first event
                }
                var prevEvent = e.events[e.events.length - 2];
                if (event.timestamp - prevEvent.timestamp < 100) {
                    continue; // XXX: drop events closer than 100 millis, just in case
                }
                var eventHandler = function (e, event) {
                    return function () {
                        console.log("event handling timing offset: " + (new Date() - event.timestamp - timeShift));
                        var timeDif = new Date() - e.timestamp;
                        var posDif = event.pos - e.pos;
                        e.dir = posDif == 0 ? e.dir : (posDif > 0 ? "outbound" : "inbound");
                        e.speedFrom = e.speed;
                        e.speed = Math.abs(posDif) * 3600 / timeDif; // from meters per milli to kph
                        if (e.speed > 60) {
                            console.warn(e.bikeId + " is going a bit fast at " + e.speed + " kph.");
                        }
                        // e.speed = event.speed
                        var time = new Date(event.timestamp);
                        var logTime = time.getMinutes() + ":" + time.getSeconds() + "." + time.getMilliseconds();
                        console.log(e.bikeId + "@" + logTime + ": handling event to advance by " + posDif + " to " + event.pos + " in " + timeDif + " millis " + e.dir + ". Pos at " + e.pos + " doing " + e.speed + "kph");
                        e.assist = event.assistLevel;
                        e.effort = event.effortLevel;
                    }
                };
                setTimeout(eventHandler(e, event), displayDelay);
            }
        }

        selectedRoute.entities.length = 0;
        // start the animation loop
        setInterval(animate, frameLen);
        // start the event processing
        setInterval(processEvents, 100); // once a second


        function generateEvents() {
    
            function pushEvent(timestamp, bikeId, pos) {
                var atIdxOfPath = Math.trunc((pos / lineDistance) * outbound.length);
                if (atIdxOfPath < 0 || atIdxOfPath > outbound.length - 1) {
                    return; // stop generating events beyond the route's ends
                }
                var location = outbound[atIdxOfPath];
                var genPoint = turf.point(location); // turf.transformTranslate(, Math.random() * 2, Math.random() * 360, {units: "meters"}); // introduce error up to 2 meters
                location = genPoint.geometry.coordinates;
                store.eventQueue.push({
                    timestamp: timestamp,
                    id: uuid.v4(),
                    event: "BikeMovedEvent",
                    bikeid: bikeId,
                    lon:location[0],
                    lat: location[1],
                    assistLevel: Math.random() * 20 + 50,
                    effortLevel: Math.random() * 15 + 30,
                    gpsHdop: 2.0
                });
                var time = new Date(timestamp);
                var logTime = time.getMinutes() + ":" + time.getSeconds() + "." + time.getMilliseconds();
                console.log(e.bikeId + "@" + logTime + ": pushing event to advance by " + advanceByMeters + " to " + pos + " " + e.dir + ". Pos at " + e.pos + " doing " + e.speed + "kph");
            }

            for (var i = 0; i < selectedRoute.entities.length; i++) {
                var e = selectedRoute.entities[i];
                var lastPos = 0;
                var advanceByMeters = isRestricted(e) ? 
                    (Math.random() * 3.5 + 2) * eventGeneratorInterval / 1000: // 2-5.5 meters range for every second, between 7 and 20 kph
                    (Math.random() * 6.6 + 10) * eventGeneratorInterval / 1000; // 10-16.6 meters range for every second, between 36 and 60 kph
                // if already processed events, generate new position based on previous event's position
                if (e.events && e.events.length) {
                    lastPos = e.events[e.events.length - 1].pos;
                } 
                // otherwise, generate more events for past and now to get the entity going
                else {
                    lastPos = e.pos; // pick up init
                    var now = Date.now();
                    for (var ts = now - eventGeneratorInterval * (1 + Math.trunc(timeShift / eventGeneratorInterval)); ts < now; ts+=eventGeneratorInterval) {
                        pushEvent(ts, e.bikeId, lastPos + advanceByMeters * (e.dir == "outbound" ? 1 : -1));
                        lastPos += advanceByMeters * (e.dir == "outbound" ? 1 : -1);
                    }
                }
                var newPos = lastPos + advanceByMeters * (e.dir == "outbound" ? 1 : -1);
                // delay event slightly so that not all entities get updated at once
                pushEvent(Date.now() + eventGeneratorInterval / selectedRoute.entities.length / 2 * i, e.bikeId, newPos);
            }
        }

        if (process.env.VUE_APP_BIKE_EVENTS_MODE === "streamed") {
            // get recent historical data for bikes
            // axios.get(process.env.VUE_APP_BIKE_HIST_URL).then(
            //     response => {
            //         var pastEvents = response.data;
            //         while (pastEvents.length) {
            //             store.eventQueue.push(pastEvents.pop());
            //         }
            //     }
            // );
            // connect to streaming endpoint for bike events
            let wsCli = new WebsocketClient(process.env.VUE_APP_BIKE_WS_URL, process.env.VUE_APP_BIKE_WS_TOPIC);
            wsCli.connect(tick => {
                // on every message push the event to the event store
                let payload = JSON.parse(tick.body);
                // if (payload.bikeid != "c4393586-2d6f-435d-9dbd-a13e96ad9425") {
                //     return; // XXX: discard all but one bike for debugging
                // }
                mutations.pushEvent(payload);
                console.log("Got message: " + payload);
            });
        } else if (process.env.VUE_APP_BIKE_EVENTS_MODE === "generated") {
            const startEntities = 3;
            for (var ii = 0; ii < startEntities; ii++) {
                pushEntity(spawnEntity(false, "eBike ".padEnd(12, String.fromCharCode(97+ii))));
            }

            var eventGeneratorInterval = 500; // in millis

            generateEvents(); // kickstart entities
            // start the event generation
            setInterval(generateEvents, eventGeneratorInterval);
        } else {
            console.error("Neither 'streamed' or 'generated' bike event source found in the config files.")
        }

        this.checkSelectedQueryEntity();
        EventBus.$on('entityPushed', this.checkSelectedQueryEntity);
    },
    render() { }
}