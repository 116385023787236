<script>
import SidePanelToggle from "../layout/side-panel/SidePanelToggle.vue";
import FilterPanelToggle from "../layout/filter-panel/FilterPanelToggle.vue";

export default {
  name: "MapHeader",
  components: {
    SidePanelToggle,
    FilterPanelToggle,
  },
};
</script>

<template>
  <div class="map-header">
    <b-container fluid>
      <b-row align-v="start">
        <b-col cols="5" class="map-header-col" style="padding-left: 0;">
          <close-icon :size="24" />
          <pf-expand-icon :size="20" />
          <span class="map-header-active">Snelfietsroute Velodrome V130</span>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="3" class="map-header-col" style="text-align:right; padding-right: 0;">
          <checkbox-blank-circle-outline-icon :size="24" class="iconBoundRight" />
          <FilterPanelToggle></FilterPanelToggle>
          <magnify-icon :size="24" />
          <SidePanelToggle></SidePanelToggle>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
.map-header {
  background-color: white;
  height: 45px;
}
.map-header-active {
  padding: 0 10px;
  vertical-align: -2px;
  font-size: 15px;
}
.map-header-col {
  padding: 10px;
}
</style>