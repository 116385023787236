<template>
  <div id="app">
    <b-container fluid>
      <TopNavBar v-if="panelsShown"></TopNavBar>
      <ContextNavBar v-if="panelsShown"></ContextNavBar>
    </b-container>
    <MapHolder></MapHolder>
    <Footer v-if="panelsShown"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from "@/components/layout/nav-bar/TopNavBar";
import ContextNavBar from "@/components/layout/nav-bar/ContextNavBar";
import Footer from "@/components/layout/footer/Footer";
import MapHolder from "@/components/map/MapHolder.vue";
import { store } from "@/store.js";

export default {
  name: "context",
  components: {
    MapHolder,
    TopNavBar,
    ContextNavBar,
    Footer
  },
  computed: {
    panelsShown() {
      return store.panelsShown;
    }
  }
};
</script>
<style>
</style>