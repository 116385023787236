<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-panel-asc-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      :fill="props.fillColor"
      class="material-design-icon__svg"
      :width="props.size"
      :height="props.size"
    >
      <g id="Gfx" transform="translate(0 4)">
        <circle id="Ellipse_924" data-name="Ellipse 924" cx="8.685" cy="8.685" r="8.685" />
        <circle
          id="Ellipse_925"
          data-name="Ellipse 925"
          cx="1.63"
          cy="1.63"
          r="1.63"
          transform="translate(20.827 7.056)"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfPanelASCIcon",
  props: {
    title: {
      type: String,
      default: "Panel ASC Asset Icon",
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>