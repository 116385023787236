<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 16 14"
    >
      <path
        id="Path_11524"
        data-name="Path 11524"
        d="M1241.2,334.994l-8,14h16Z"
        transform="translate(-1233.197 -334.994)"
        fill="#01b4e2"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfTravelerFilterIcon",
  props: {
    title: {
      type: String,
      default: "Traveler Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>