<template>
  <div class="ctx-navbar">
    <b-container fluid>
      <b-row align-v="start">
        <b-col cols="3" class="ctx-navbar-col">
          <div class="ctx-active">Snelfietsroute Velodrome<br/>V130</div>
        </b-col>
        <b-col cols="2" class="ctx-navbar-col-buttons">
          <filter-variant-icon :size="24" />
          <magnify-icon :size="24" />
          <plus-box-outline-icon :size="24" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>

<style scoped>
.ctx-navbar {
  background: transparent;
  margin-left: 10px;
  margin-bottom: 15px;
  font-size: .9rem;
  line-height: 1.3;
}
.ctx-navbar-row {
  padding: 3px;
  background: transparent;
}
.ctx-navbar-col {
  background: #ffffff;
  padding: 10px;
  padding-left: 20px;
  padding-top: 9px;
  height: 50px;
}

.ctx-navbar-col-buttons {
  padding: 10px;
  padding-top: 12px;
  background-color: #f4f4f6;
  height: 50px;
  flex: none;
  width: 155px;
}

.ctx-active {
  font-weight: bold;
}
</style>
