<template>
  <div id="app">
    <router-view>
      <LandingPage></LandingPage>
    </router-view>
  </div>
</template>

<script>
import LandingPage from "@/components/LandingPage.vue";

export default {
  name: "app",
  components: {
    LandingPage
  }
};
</script>
<style>
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #4e4e4e;
  background-color: #e9e9ee;
}

#app > .container-fluid {
  padding: 0;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #e9e9ee;
}
body,
a,
.navbar-button,
.navbar-button:hover,
.navbar-button:active,
.navbar-button:focus {
  color: rgba(0, 0, 0, 0.78);
}

.form-input {
  border: none;
  border-bottom: 1px solid #e2e2e2;
}

.form-input:focus {
  background-color: #ffffff;
  outline: none;
  border: none;
  border-bottom: 2px solid #fefe00;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: #fefe00 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tab-active,
.router-link-active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
  background-color: transparent;
  border: none;
}

.tab-active:hover {
  background-color: transparent;
  color: #6c757d;
}

.material-design-icon {
  color: #949494;
  padding: 10px;
  padding-bottom: 15px;
  padding-top: 14px;

}

.material-design-icon:hover {
  background-color: #ffff00;
  cursor: pointer;
  color: #6c757d;
}

.iconBoundBoth {
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
}

.iconBoundRight {
  border-right: 1px solid #d6d6d6;
}

.btn-container {
  float: right;
}

.active {
  background-color: #000;
  color: white;
}

.btn-secondary {
  background-color: white;
}
</style>