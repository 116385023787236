<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-expand-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      :fill="props.fillColor"
      class="material-design-icon__svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 25 25"
    >
      <g id="Group_65" transform="translate(8632 13471)" class="st0">
        <g id="Group_63" transform="translate(-10323.25 -14154.25)">
          <g id="Rectangle_13" transform="translate(1691.25 683.25)">
            <rect y="0" class="st1" width="25" height="25" />
            <rect x="0.8" y="0.8" class="st2" width="23.5" height="23.5"/>
          </g>
        </g>
        <g id="Group_64" transform="translate(-10324 -14155)">
          <path
            id="Path_9"
            d="M1695.5,687c-0.3,0-0.5,0.3-0.5,0.6v3.4c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0,0,0,0,0,0v-2l2.4,2.4
            c0.2,0.2,0.6,0.2,0.8,0c0.2-0.2,0.2-0.6,0-0.8l0,0l-2.4-2.4h2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0
            L1695.5,687L1695.5,687z M1710,687c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0h2l-2.4,2.4c-0.2,0.2-0.2,0.6,0,0.8
            c0.2,0.2,0.6,0.2,0.8,0l2.4-2.4v2c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0v-3.4c0-0.3-0.3-0.6-0.6-0.6H1710z
             M1699,701.4c-0.2,0-0.3,0.1-0.4,0.2l-2.4,2.4v-2c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6c0,0,0,0,0,0v3.4c0,0.3,0.3,0.6,0.6,0.6
            c0,0,0,0,0,0h3.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0h-2l2.4-2.4c0.2-0.2,0.2-0.6,0-0.8
            C1699.3,701.5,1699.1,701.4,1699,701.4L1699,701.4z M1710,701.4c-0.3,0-0.5,0.3-0.5,0.6c0,0.1,0.1,0.3,0.2,0.4l2.4,2.4h-2
            c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0h3.4c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0V702c0-0.3-0.3-0.6-0.6-0.6
            s-0.6,0.3-0.6,0.6c0,0,0,0,0,0v2l-2.4-2.4C1710.3,701.5,1710.1,701.4,1710,701.4z"
          />
        </g>
      </g>
      <title>{{ props.title }}</title>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfExpandIcon",
  props: {
    title: {
      type: String,
      default: "Expand icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 30
    }
  }
};
</script>

<style scoped>
.st0 {
  opacity: 0.3;
}
.st1 {
  fill: none;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
}
</style>