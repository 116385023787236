<script>
import Avatar from 'vue-avatar'
export default {
  components: { Avatar }
};
</script>

<template>
  <div class="top-navbar">
    <b-container fluid>
      <b-row align-v="start" class="top-navbar-row">
        <b-col md="auto" cols="1" class="top-navbar-col" style="padding: 0;">
          <div style="float: left; padding-top: 8px; padding-left:8px;margin-right: 10px;">
            <router-link to="/landing">
              <Avatar username="T B" :size="32" backgroundColor="#000000" color="#ffffff"/>
            </router-link>
          </div>
          <img class="navbar-logo" src="../../../assets/img/TM_Org_Icon.svg" />
        </b-col>
        <b-col md="auto" class="top-navbar-col-button">
          <b-button href="#" class="navbar-button">CONFIGURE</b-button>
        </b-col>
        <b-col md="auto" class="top-navbar-col-button">
          <b-button href="#" class="navbar-button">ORGANIZE</b-button>
        </b-col>
        <b-col md="auto" class="top-navbar-col-button">
          <b-button href="#" class="navbar-button">CAPTURE</b-button>
        </b-col>
        <b-col md="auto" class="top-navbar-col-button">
          <b-button href="#" class="navbar-button">PLAN</b-button>
        </b-col>
        <b-col md="auto" class="top-navbar-col-button-active" style="margin-right: 0px; border-right: 1px solid #dcdcdc">
          <b-button href="#" class="navbar-button-active">OPERATE</b-button>
        </b-col>
        <b-col md="auto" class="top-navbar-col-button-active" style="margin-right: 0px; padding-top: 5px">
          <b-button style="width: 220px" href="#" class="navbar-button-active">SnelFietsRoute<br/>Velodrome V130</b-button>
        </b-col>
        <b-col md="auto" class="top-navbar-col-button-active" style="margin-right: 5px; padding-top: 22px">
          <filter-variant-icon :size="24" />
          <magnify-icon :size="24" />
          <plus-box-outline-icon :size="24" />
        </b-col>
        <b-col md="auto" class="top-navbar-col-button">
          <b-button href="#" class="navbar-button">ASSESS</b-button>
        </b-col>
        <b-col class="top-navbar-col-button" style="margin-right: 0px">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.top-navbar {
  background: transparent;
}
.top-navbar-row {
  padding-bottom: 10px;
}
.top-navbar-col-button,
.top-navbar-col,
.top-navbar-col-button-active {
  background: #ffffff;
  margin-right: 5px;
  height: 50px;
  text-align: center;
}
.top-navbar-col-button {
  padding-top: 8px;
}

.top-navbar-col-button-active {
  height: 70px;
  padding-top: 18px;
  margin-right: 0;
}

.navbar-button-active {
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: #000;
}
.navbar-button-active:hover {
  background-color: transparent;
}

.navbar-button,
.navbar-button:hover,
.navbar-button:focus,
.navbar-button:active {
  background-color: white;
  color: #999;
  border: none;
  font-size: .8rem;
  font-weight: bold;
  padding-top: 8px;
}

.navbar-logo,
.navbar-user {
  height: 50px;
}
</style>
