<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_11523"
        data-name="Path 11523"
        d="M1250.025,523.572a3.527,3.527,0,0,1,0,4.975l-6.485,6.486a3.527,3.527,0,0,1-4.975,0l-6.487-6.486a3.529,3.529,0,0,1,0-4.975l6.487-6.487a3.53,3.53,0,0,1,4.975,0Zm-7.262,2.487a1.711,1.711,0,1,0-1.711,1.711A1.71,1.71,0,0,0,1242.762,526.059Z"
        transform="translate(-1231.052 -516.06)"
        fill="#ffe11a"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfSignageFilterIcon",
  props: {
    title: {
      type: String,
      default: "Signage Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>