import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '@/components/LandingPage'
import Context from '@/components/Context'
import { mutations } from '@/store.js'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage
  },
  {
    path: '/context',
    name: 'context',
    component: Context,
    beforeEnter: (to, from, next) => {
      mutations.loadGeoFences();
      next();
    },
    children: [
      {
        path: 'filter',
        component: () => import(/* webpackChunkName: "filter" */ '@/components/layout/filter-panel/FilterPanel'),
        beforeEnter: (to, from, next) => {
          mutations.openFilterPanel();
          next()
        }
      },
      {
        path: 'asset',
        component: () => import(/* webpackChunkName: "asset" */ '@/components/layout/side-panel/side-panel-content/Asset'),
        beforeEnter: (to, from, next) => {
          mutations.openSidePanel();
          next();
        }
      },
      {
        path: 'protocol',
        component: () => import(/* webpackChunkName: "protocol" */ '@/components/layout/side-panel/side-panel-content/Protocol'),
        beforeEnter: (to, from, next) => {
          mutations.openSidePanel();
          next()
        }
      },
      {
        path: 'feature',
        component: () => import(/* webpackChunkName: "feature" */ '@/components/layout/side-panel/side-panel-content/Feature'),
        beforeEnter: (to, from, next) => {
          mutations.openSidePanel();
          next()
        },
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  routes
});

export default router
