<template>
  <router-link :to="routerLink" class="router-link">
    <filter-variant-icon
      :size="24"
      class="toggle-button"
      id="filter-panel-toggle"
      :class="{ active: isFilterPanelToggleActive }"
      @click="toggle"
    ></filter-variant-icon>
  </router-link>
</template>

<script>
import { store, mutations } from "@/store.js";

export default {
  computed: {
    isFilterPanelToggleActive() {
      return store.isFilterPanelOpen;
    },
    routerLink() {
      return store.isFilterPanelOpen ? "/context/filter" : "/context";
    }
  },
  methods: {
    toggle() {
      mutations.toggleFilterPanel();
    },
  },
};
</script>

<style scoped>
</style>
