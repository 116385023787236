<script>
import { store, mutations } from "@/store.js";

export default {
  methods: {
    closeSidePanel: mutations.toggleSidePanel,
    toggleFullscreen: mutations.toggleSidePanelFullscreen
  },
  data() {
    return {
      zoom: 16,
      center: [4.759359, 52.320227]
    };
  },
  computed: {
    isSidePanelOpen() {
      return store.isSidePanelOpen;
    },
    sidePanelDisplayClass() {
      return store.sidePanelDisplayClass;
    }
  },
  components: {},
  created() {
    if (this.$route.query.fullscreen === 'fullscreen') {
      store.sidePanelDisplayClass = '';
      mutations.toggleSidePanelFullscreen();
    }
  }
};
</script>

<template>
  <div class="side-panel" @keydown.esc="toggleFullscreen">
    <transition name="slide">
      <div v-show="isSidePanelOpen" class="side-panel-panel" id="main-side-panel" :class="sidePanelDisplayClass">
        <b-container fluid>
          <b-row align-v="center" class="side-panel-header" style="padding: 0">
            <b-col cols="1" style="text-align: center; padding: 0;">
              <pencil-icon :size="24" />
            </b-col>
            <b-col cols="2">
              <div>
                <router-link to="/context/feature" class="router-link">FEATURE</router-link>
              </div>
            </b-col>
            <b-col cols="3">
              <div>
                <router-link to="/context/protocol" class="router-link">PROTOCOL</router-link>
              </div>
            </b-col>
            <b-col cols="2">
              <div>
                <router-link to="/context/asset" class="router-link">INSTANCE</router-link>
              </div>
            </b-col>
            <b-col cols="4" style="padding: 0; text-align: right;">
              <filter-variant-icon :size="24" class="toggle-button disabled" />
              <magnify-icon :size="24" class="toggle-button disabled" />
              <plus-box-outline-icon :size="24" class="toggle-button disabled" @click="toggleFullscreen" />
            </b-col>
          </b-row>
        </b-container>
        <router-view ref="side-panel-content" class="side-panel-content" :class="sidePanelDisplayClass"></router-view>
        <div class="side-panel-footer">
          <div class="side-panel-footer-icons">
            <pf-conversation-icon :size="18" />
            <share-variant-icon :size="24" />
            <download-icon :size="24" class="iconBoundRight" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.router-link {
  font-weight: bold;
  font-size: 0.8rem;
}
</style>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.side-panel-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.side-panel-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  position: fixed;
  right: 0;
  height: calc(100% - 235px);
  z-index: 999;
  width: 40%;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.side-panel-content {
  height: 100%;
}

.side-panel-header {
  text-align: center;
  border-bottom: 1px solid #d6d6d6;
}

.side-panel-footer,
.side-panel-header {
  padding: 5px;
  height: 47px;
}

.side-panel-footer-icons {
  padding: 6px;
}

.router-link.router-link-active {
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: #000000;
}

.router-link {
  background-color: transparent;
  border: none;
  color: #c9c8cd;
}

.router-link:hover {
  color: inherit;
  text-decoration: none;
}

.side-panel-content.fullscreen,
.side-panel-panel.fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.side-panel-content.fullscreen .side-panel-header-sub {
  display: none;
}

.toggle-button.disabled {
  pointer-events: none;
  color: #e4e4e4;
}

.side-panel-content.fullscreen .map-container,
.side-panel-content.fullscreen .ebike-dash-container {
  width: 100%;
  height: 100%;
}
</style>
