/* eslint-disable no-unused-vars*/

import Vue from "vue";
import routeContents from "./routedata/content.json"
import routePresentations from "./routedata/presentation.json"

const routeDataStructure = {
    id: 'snelfietsroute',
    eventMisses: {
        features: [],
        type: "FeatureCollection"

    },
    eventHits: {
        features: [],
        type: "FeatureCollection"

    },
    entities: []
}

export const OBJECT_TYPE = {
    ASC: "ASC",
    EBIKE: "eBike"
};

export const store = Vue.observable({
    isSidePanelOpen: false,
    isFilterPanelOpen: false,
    sidePanelDisplayClass: '',
    panelsShown: true,
    isAuthenticated: true,
    routes: [],
    eventQueue: [],
    geoFences: [],
    selectedRoute: undefined,
    selectedSegment: undefined,
    selectedFeature: undefined,
    selectedObject: undefined,
    ascSelected: true, // XXX: should actually check whether the selectedFeature == selectedObject, but did not tie reactivity properly
    filters: {
        vehicles: {
            eBikes: []
        },
        ascs: [
            {
              name: "V130",
              show: true,
              showChildren: true,
              selected: false,
              children: [],
              type: OBJECT_TYPE.ASC,
              objectId: "V130"
            },
            {
              name: "F201",
              show: true,
              showChildren: true,
              selected: false,
              children: [],
              type: OBJECT_TYPE.ASC,
              disabled: true
            },
            {
              name: "F202",
              show: true,
              showChildren: true,
              selected: false,
              children: [],
              type: OBJECT_TYPE.ASC,
              disabled: true
            }
        ]
    }
});

export const mutations = {
    initRoute(id) {
        var content = routeContents[id];
        var presentation = routePresentations[id];
        store.routes.push(Object.assign({}, routeDataStructure, content, presentation));
    },
    selectFeature(route, segment, feature) {
        store.selectedRoute = route;
        store.selectedSegment = segment;
        store.selectedFeature = feature;
    },
    toggleSidePanel() {
        store.isSidePanelOpen = !store.isSidePanelOpen;
        store.isFilterPanelOpen = false;
    },
    openSidePanel() {
        store.isSidePanelOpen = true;
        store.isFilterPanelOpen = false;
    },
    toggleFilterPanel() {
        store.isFilterPanelOpen = !store.isFilterPanelOpen;
        store.isSidePanelOpen = false;
    },
    openFilterPanel() {
        store.isFilterPanelOpen = true;
        store.isSidePanelOpen = false;
    },
    toggleSidePanelFullscreen() {
        var maximizing = !store.sidePanelDisplayClass;
        store.sidePanelDisplayClass =  maximizing ? 'fullscreen' : '';
        store.panelsShown = !maximizing;
        this.$refs["side-panel-content"].$el.dispatchEvent(new Event('resize'));
    },
    refreshDetailLayoutVisibility(value) {
        var sfr = store.routes[0];
        for (var mapId in sfr.detailLayout) {
            sfr.detailMarkersLayout[mapId].visibility = (value && sfr.detailLayout[mapId].visibility == "visible") ? "visible" : "none";
        }
    },
    pushEvent(event) {
        var latest = store.eventQueue.length ? store.eventQueue[store.eventQueue.length - 1] : null;
        if (latest && latest.timestamp > event.timestamp) {
            console.warn('dropped event that did not come in order', event);
            return;
        }
        store.eventQueue.push(event);
    },
    loadGeoFences() {
        console.log("Temporary disabled until the backend supports this method again.")
        // axios
        //     .get(process.env.VUE_APP_BIKE_API + "cyclists/geofences")
        //     .then(response => {
        //         store.geoFences = response;
        //     })
        //     .catch(error => {
        //         console.error("Failed to load geofences with error: " + error);
        //     });
    },
    setGeoFenceSpeedLimit(fenceId, limit) {
        console.log("Temporary disabled until the backend supports this method again.")
        // axios
        //     .put(process.env.VUE_APP_BIKE_API + "/cyclists/geofences/" + fenceId + "/speed-limit", {
        //         "speedLimitInKmh": limit
        //     })
        //     .catch(error => {
        //         console.error("Failed to set geofences speed limit with error: " + error);
        //     })
    },
    deleteGeoFenceSpeedLimit(fenceId) {
        console.log("Temporary disabled until the backend supports this method again.")
        // axios
        //     .delete(process.env.VUE_APP_BIKE_API + "/cyclists/geofences/" + fenceId + "/speed-limit")
        //     .catch(error => {
        //         console.error("Failed to delete geofences speed limit with error: " + error);
        //     })
    }

};

mutations.initRoute("snelfietsroute"); // init snelfietsroute
mutations.selectFeature(store.routes[0], store.routes[0].segments[0], store.routes[0].segments[0].features[0]); // select ASC.V130 feature

store.selectedObject = store.routes[0].segments[0].features[0];
