import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import './assets/scss/custom.scss'
import * as turf from '@turf/turf';
import Icons from "@/components/layout/VueMaterialIcons.vue";
import CustomIcons from "@/components/layout/PfCustomIcons.vue";
import router from './router';
import resize from "vue-resize-directive";

Object.defineProperty(Vue.prototype, '$turf', { value: turf });

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Icons);
Vue.use(CustomIcons);
Vue.directive('resize', resize);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')