<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      id="Group_11719"
      data-name="Group 11719"
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 18 18"
    >
      <ellipse
        id="Ellipse_1152"
        data-name="Ellipse 1152"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(3.463 10.388)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1153"
        data-name="Ellipse 1153"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(0 6.925)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1154"
        data-name="Ellipse 1154"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(3.463 3.463)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1155"
        data-name="Ellipse 1155"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(6.925 13.851)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1156"
        data-name="Ellipse 1156"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(10.388 10.388)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1157"
        data-name="Ellipse 1157"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(6.925 6.925)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1158"
        data-name="Ellipse 1158"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(6.925)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1159"
        data-name="Ellipse 1159"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(13.851 6.925)"
        fill="#7bc145"
      />
      <ellipse
        id="Ellipse_1160"
        data-name="Ellipse 1160"
        cx="2.075"
        cy="2.075"
        rx="2.075"
        ry="2.075"
        transform="translate(10.388 3.463)"
        fill="#7bc145"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfGreenFilterIcon",
  props: {
    title: {
      type: String,
      default: "Green Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>