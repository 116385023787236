<script>
import ChatIcon from "vue-material-design-icons/Chat.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import ArrowExpandAllIcon from "vue-material-design-icons/ArrowExpandAll.vue";
import RocketIcon from "vue-material-design-icons/Rocket.vue";
import CheckboxBlankCircleOutlineIcon from "vue-material-design-icons/CheckboxBlankCircleOutline.vue";
import FilterVariantIcon from "vue-material-design-icons/FilterVariant.vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import MessageOutlineIcon from "vue-material-design-icons/MessageOutline.vue";
import ClipboardPulseOutlineIcon from "vue-material-design-icons/ClipboardPulseOutline.vue";
import PlusBoxOutlineIcon from "vue-material-design-icons/PlusBoxOutline.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import ChartBubbleIcon from "vue-material-design-icons/ChartBubble.vue";
import DownloadIcon from "vue-material-design-icons/Download.vue";
import ShareVariantIcon from "vue-material-design-icons/ShareVariant.vue";

export default {
  install(Vue) {
    Vue.component("chat-icon", ChatIcon);
    Vue.component("menu-icon", MenuIcon);
    Vue.component("close-icon", CloseIcon);
    Vue.component("arrow-expand-all-icon", ArrowExpandAllIcon);
    Vue.component("rocket-icon", RocketIcon);
    Vue.component(
      "checkbox-blank-circle-outline-icon",
      CheckboxBlankCircleOutlineIcon
    );
    Vue.component("filter-variant-icon", FilterVariantIcon);
    Vue.component("magnify-icon", MagnifyIcon);
    Vue.component("message-outline-icon", MessageOutlineIcon);
    Vue.component("clipboard-pulse-outline-icon", ClipboardPulseOutlineIcon);
    Vue.component("plus-box-outline-icon", PlusBoxOutlineIcon);
    Vue.component("pencil-icon", PencilIcon);
    Vue.component("chart-bubble-icon", ChartBubbleIcon);
    Vue.component("download-icon", DownloadIcon); 
    Vue.component("share-variant-icon", ShareVariantIcon); 
  }
};
</script>