<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 24 10.545"
    >
      <path
        id="Path_11522"
        data-name="Path 11522"
        d="M1228.773,468.71V458.165h24V468.71Zm21.606-5.273a2.715,2.715,0,1,0-2.715,2.715A2.715,2.715,0,0,0,1250.379,463.437Zm-6.885,0a2.715,2.715,0,1,0-2.714,2.715A2.715,2.715,0,0,0,1243.494,463.437Zm-6.884,0a2.715,2.715,0,1,0-2.714,2.715A2.715,2.715,0,0,0,1236.609,463.437Z"
        transform="translate(-1228.773 -458.165)"
        fill="#ff7600"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfSTCFilterIcon",
  props: {
    title: {
      type: String,
      default: "STC Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>