<script>
import Vue from "vue";
import PfVehiclesFilterIcon from "./icons/PfVehiclesFilterIcon.vue";
import PfAdaptiveSpeedFilterIcon from "./icons/PfAdaptiveSpeedFilterIcon.vue";
import PfFilterIconPlaceholder from "./icons/PfFilterIconPlaceholder.vue";
import PfPathsFilterIcon from "./icons/PfPathsFilterIcon.vue";
import PfHubsFilterIcon from "./icons/PfHubsFilterIcon.vue";
import PfTravelerFilterIcon from "./icons/PfTravelerFilterIcon.vue";
import PfSTCFilterIcon from "./icons/PfSTCFilterIcon.vue";
import PfIlluminationFilterIcon from "./icons/PfIlluminationFilterIcon.vue";
import PfSignageFilterIcon from "./icons/PfSignageFilterIcon.vue";
import PfNetworkFilterIcon from "./icons/PfNetworkFilterIcon.vue";
import PfGreenFilterIcon from "./icons/PfGreenFilterIcon.vue";
import PfArtFilterIcon from "./icons/PfArtFilterIcon.vue";

Vue.component("pf-vehicles-filter-icon", PfVehiclesFilterIcon);
Vue.component("pf-adaptivespeed-filter-icon", PfAdaptiveSpeedFilterIcon);
Vue.component("pf-filter-icon-placeholder", PfFilterIconPlaceholder);
Vue.component("pf-paths-filter-icon", PfPathsFilterIcon);
Vue.component("pf-hubs-filter-icon", PfHubsFilterIcon);
Vue.component("pf-traveler-filter-icon", PfTravelerFilterIcon);
Vue.component("pf-stc-filter-icon", PfSTCFilterIcon);
Vue.component("pf-illumination-filter-icon", PfIlluminationFilterIcon);
Vue.component("pf-signage-filter-icon", PfSignageFilterIcon);
Vue.component("pf-network-filter-icon", PfNetworkFilterIcon);
Vue.component("pf-green-filter-icon", PfGreenFilterIcon);
Vue.component("pf-art-filter-icon", PfArtFilterIcon);

export default {
  name: "FilterTreeIcon",
  computed: {},
  props: {
    icon: {
      type: String,
    },
    className: {
      type: String,
    },
  },
};
</script>

<template >
  <pf-vehicles-filter-icon v-if="this.icon == 'pf-vehicles-filter-icon'" className="filter-icon" />
  <pf-adaptivespeed-filter-icon v-else-if="this.icon == 'pf-adaptivespeed-filter-icon'" className="filter-icon" />
  <pf-paths-filter-icon v-else-if="this.icon == 'pf-paths-filter-icon'" className="filter-icon" />
  <pf-hubs-filter-icon v-else-if="this.icon == 'pf-hubs-filter-icon'" className="filter-icon" />
  <pf-traveler-filter-icon v-else-if="this.icon == 'pf-traveler-filter-icon'" className="filter-icon" />
  <pf-stc-filter-icon v-else-if="this.icon == 'pf-stc-filter-icon'" className="filter-icon" />
  <pf-illumination-filter-icon v-else-if="this.icon == 'pf-illumination-filter-icon'" className="filter-icon" />
  <pf-signage-filter-icon v-else-if="this.icon == 'pf-signage-filter-icon'" className="filter-icon" />
  <pf-network-filter-icon v-else-if="this.icon == 'pf-network-filter-icon'" className="filter-icon" />
  <pf-green-filter-icon v-else-if="this.icon == 'pf-green-filter-icon'" className="filter-icon" />
  <pf-art-filter-icon v-else-if="this.icon == 'pf-art-filter-icon'" className="filter-icon" />
  <pf-filter-icon-placeholder v-else-if="true" />
</template>

<style>
.filter-icon {
  opacity: .3;
}
.teatree-node-item-selected .filter-icon {
  opacity: 1;
}
</style>