<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="[data.class, data.staticClass]"
    class="material-design-icon pf-conversation-icon"
    role="img"
    v-on="listeners"
  >
    <svg
      :fill="props.fillColor"
      class="material-design-icon__svg"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 25 25"
    >
      <g id="Group_648" transform="translate(-32 -807)" class="st0">
        <g id="Group_1166" transform="translate(-818 772)">
          <g id="Rectangle_3" transform="translate(850 35)">
            <rect y="0" class="st1" width="25" height="25" />
            <rect x="0.8" y="0.8" class="st2" width="23.5" height="23.5" />
          </g>
        </g>
        <path id="Path_382" class="st2" d="M33,819.5h6.2l2.4-3.1l3.2,8.5l3.2-10.9l2,6.1h5.5" />
      </g>
      <title>{{ props.title }}</title>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfConversationIcon",
  props: {
    title: {
      type: String,
      default: "Conversation icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 30
    }
  }
};
</script>

<style scoped>
.st0 {
  opacity: 0.3;
}
.st1 {
  fill: none;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
}
</style>