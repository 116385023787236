<script>
import PfExpandIcon from "./icons/PfExpandIcon.vue";
import PfConversationIcon from "./icons/PfConversationIcon.vue";
import PfActivityIcon from "./icons/PfActivityIcon.vue";
import PfPanelASCIcon from "./icons/PfPanelASCIcon.vue";

import PfVehiclesFilterIcon from "./filter-panel/icons/PfVehiclesFilterIcon.vue";

export default {
  install(Vue) {
    Vue.component("pf-expand-icon", PfExpandIcon);
    Vue.component("pf-conversation-icon", PfConversationIcon);
    Vue.component("pf-acvitity-icon", PfActivityIcon);
    Vue.component("pf-panel-asc-icon", PfPanelASCIcon);

    Vue.component("pf-vehicles-icon", PfVehiclesFilterIcon);
  }
};
</script>