<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      id="Group_11717"
      data-name="Group 11717"
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 24 24"
    >
      <g
        id="Ellipse_1149"
        data-name="Ellipse 1149"
        fill="none"
        stroke="#f6e497"
        stroke-miterlimit="10"
        stroke-width="0.25"
      >
        <circle cx="12" cy="12" r="12" stroke="none" />
        <circle cx="12" cy="12" r="11.875" fill="none" />
      </g>
      <g
        id="Ellipse_1150"
        data-name="Ellipse 1150"
        transform="translate(5.096 5.096)"
        fill="none"
        stroke="#f6e497"
        stroke-miterlimit="10"
        stroke-width="1"
      >
        <ellipse cx="6.904" cy="6.904" rx="6.904" ry="6.904" stroke="none" />
        <ellipse cx="6.904" cy="6.904" rx="6.404" ry="6.404" fill="none" />
      </g>
      <ellipse
        id="Ellipse_1151"
        data-name="Ellipse 1151"
        cx="2.796"
        cy="2.796"
        rx="2.796"
        ry="2.796"
        transform="translate(9.204 9.204)"
        fill="#f6e497"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfIlluminationFilterIcon",
  props: {
    title: {
      type: String,
      default: "Illumination Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>