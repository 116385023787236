<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 28.284 28.284"
    >
      <path
        id="Path_11526"
        data-name="Path 11526"
        d="M20,20H0V0H20V20ZM7.65,4.26A2.475,2.475,0,1,0,5.175,6.735,2.475,2.475,0,0,0,7.65,4.26Zm0,5.895A2.475,2.475,0,1,0,5.175,12.63,2.475,2.475,0,0,0,7.65,10.155Zm9.719-3.42A2.475,2.475,0,1,0,14.895,9.21,2.475,2.475,0,0,0,17.369,6.735Zm0,5.939a2.475,2.475,0,1,0-2.475,2.476A2.475,2.475,0,0,0,17.369,12.674ZM7.65,16.05a2.475,2.475,0,1,0-2.475,2.475A2.475,2.475,0,0,0,7.65,16.05Z"
        transform="translate(14.142) rotate(45)"
        fill="#1f8a70"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfHubsFilterIcon",
  props: {
    title: {
      type: String,
      default: "Hubs Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>