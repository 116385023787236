<template>
  <div class="landing">
    <div class="sidebar">
      <div class="sidebar-signin">SIGN IN</div>
    </div>
    <div class="form-container">
      <div class="signin-header">
        <p class="signin-header-notice">Sign in with your email address</p>
      </div>
      <b-form @submit="onSubmit" @reset="onReset" autocomplete="off">
        <b-form-group
          id="input-email-group"
          label="E-mail:"
          label-for="input-email"
          class="input-group-label"
        >
          <b-form-input class="form-input" id="input-email" v-model="form.email" required></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-password-group"
          label="Password:"
          label-for="input-password"
          class="input-group-label"
        >
          <b-form-input
            id="input-password"
            v-model="form.password"
            required
            class="form-input"
            type="password"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0">Remember me</b-form-checkbox>
        </b-form-group>

        <div class="buttons-container">
          <b-form-group>
            <b-button
              type="submit"
              variant="primary"
              style="width: 100%; background-color: #1488C6; color: white"
            >SIGN IN</b-button>
          </b-form-group>
          <b-form-group style="text-align: center">
            <b-link @click="onReset" style="color: #4e4e4e;">Reset Password</b-link>
          </b-form-group>
        </div>
      </b-form>
      <div class="copyright" v-if="copyright">
        <span
          class="copyright-notice"
        >© Townmaking Institute, usage, replication, and reproduction subject to terms with Townmaking and partners.</span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "landing-page",
  data() {
    return {
      mockAccounts: [
        {
          email: "tb@tm.com",
          password: "scene-Vermont-branch",
        },
      ],
      form: {
        email: "",
        password: "",
      },
      copyright: process.env.VUE_APP_SHOW_COPYRIGHT === 'true'
    };
  },
  methods: {
    onSubmit() {
      if (this.form.email !== "" && this.form.password !== "") {
        if (
          (this.form.email === this.mockAccounts[0].email &&
            this.form.password === this.mockAccounts[0].password) ||
          (this.form.email === this.mockAccounts[1].email &&
            this.form.password === this.mockAccounts[1].password)
        ) {
          this.$router.push({ name: "context" });
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
    },
  },
};
</script>

<style scoped>
.landing {
  background: url("../assets/ME_LogIn_BG_3996x2160.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.form-container {
  width: 375px;
  margin: 0 auto;
  margin-top: 80px;
  background-color: white;
  padding: 45px;
  padding-bottom: 20px;
}

.signin-header {
  border-bottom: 2px solid #969696;
  font-size: 30px;
  padding-top: 20px;
  margin-bottom: 25px;
}

.form-input {
  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.buttons-container {
  margin-top: 20px;
}

label {
  font-size: 16px;
}

.sidebar {
  position: absolute;
  width: 50px;
  height: 100%;
  background-color: #3b4046;
}

.sidebar-signin {
  background-color: white;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.input-group-label {
  font-size: 13px;
}

.copyright-notice {
  font-size: 9pt;
  color: #666;
}
</style>