<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 25.959 12.172"
    >
      <g id="Group_11898" data-name="Group 11898" transform="translate(-1347.112 -349)">
        <ellipse
          id="Ellipse_1147"
          data-name="Ellipse 1147"
          cx="6.086"
          cy="6.086"
          rx="6.086"
          ry="6.086"
          transform="translate(1347.112 349)"
        />
        <ellipse
          id="Ellipse_1148"
          data-name="Ellipse 1148"
          cx="6.086"
          cy="6.086"
          rx="6.086"
          ry="6.086"
          transform="translate(1360.899 349)"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfVehiclesFilterIcon",
  props: {
    title: {
      type: String,
      default: "Vehicles Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 24,
    },
    className: {
      type: String
    },
  },
};
</script>

<style scoped>
</style>