<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 9.657 17.323"
    >
      <path
        id="Path_11527"
        data-name="Path 11527"
        d="M1244.47,647.014a4.828,4.828,0,1,0-5.71,4.738v7.756h1.763v-7.756A4.823,4.823,0,0,0,1244.47,647.014Z"
        transform="translate(-1234.813 -642.185)"
        fill="#999"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfNetworkFilterIcon",
  props: {
    title: {
      type: String,
      default: "Network Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>