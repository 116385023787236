<template functional>
  <span
    :aria-hidden="props.decorative"
    :aria-label="props.title"
    :class="props.className"
    role="img"
    v-on="listeners"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="props.fillColor"
      :width="props.size"
      :height="props.size"
      viewBox="0 0 17.328 17.327"
    >
      <path
        id="Path_11528"
        data-name="Path 11528"
        d="M1250.116,681.1a8.664,8.664,0,1,1-8.664-8.664A8.664,8.664,0,0,1,1250.116,681.1Zm-3.786-1.762h-9.756v3.523h9.756Z"
        transform="translate(-1232.789 -672.439)"
        fill="#999"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "PfPathsFilterIcon",
  props: {
    title: {
      type: String,
      default: "Paths Filter Icon",
    },
    decorative: {
      type: Boolean,
      default: true,
    },
    fillColor: {
      type: String,
      default: "#516cb8",
    },
    size: {
      type: Number,
      default: 20,
    },
    className: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>