<script>
import Avatar from "vue-avatar";
export default {
  components: { Avatar },
  data: function() {
    return {
      copyright: process.env.VUE_APP_SHOW_COPYRIGHT === 'true'
    }
  }
};
</script>

<template>
  <div class="footer">
    <b-container fluid>
      <b-row align-h="start" align-v="start">
        <b-col class="footer-col">
          <pf-conversation-icon :size="18" class="iconBoundRight" />
          <pf-acvitity-icon :size="18" class="iconBoundRight" />
        </b-col>
        <b-col cols="8"></b-col>
        <b-col class="avatar-col">
          <b-container fluid>
            <b-row align-h="end">
              <b-col cols="1" class="avatar-cell">
                <Avatar
                  :src="require('@/assets/img/Avatar_1.png')"
                  :size="32"
                  backgroundColor="#ff7600"
                  color="#ffffff"
                />
              </b-col>
              <b-col cols="1" class="avatar-cell">
                <Avatar username="T B" :size="32" backgroundColor="#ff7600" color="#ffffff" />
              </b-col>
              <b-col cols="1" class="avatar-cell">
                <Avatar username="G N" :size="32" backgroundColor="#578E11" color="#ffffff" />
              </b-col>
              <b-col cols="1" class="avatar-cell">
                <Avatar
                  :src="require('@/assets/img/Avatar_2.png')"
                  :size="32"
                  backgroundColor="#ff7600"
                  color="#ffffff"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>

    <div class="copyright" v-if="copyright">
      <span
        class="copyright-notice"
      >© Townmaking Institute</span>
    </div>
  </div>
</template>

<style scoped>
.copyright {
  position: absolute;
  bottom: 70px;
  color: #b1b1b1;
  left: 20px;
  width: 500px;
  font-size: 9pt;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.footer {
  background: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
}
.avatar-col {
  padding: 7px;
  margin-right: 10px;
}
.avatar-cell {
  margin-right: 10px;
}
.footer-col {
  background: #ffffff;
  padding: 10px;
  padding-left: 0px;
}
</style>